@import 'sass/reset';
@import 'sass/animate';
@import 'sass/color';
@import 'sass/layout';
@import 'sass/typography';
@import 'sass/cursor';
@import 'sass/cursor_pr';
// @import 'sass/locomotive';



// Header & Footer
@import 'sass/header';
@import 'sass/header_pr';
@import 'sass/header_home';
@import 'sass/footer';
@import 'sass/footer_nav';
@import 'sass/footer_ab';
@import 'sass/footer_pr';
@import 'sass/loader';
@import 'sass/loaderLight';
@import 'sass/shutter';



// @import 'sass/pages/home';
@import 'sass/pages/home';
@import 'sass/pages/home_ex';
@import 'sass/pages/about';
@import 'sass/pages/project';
@import 'sass/pages/contact';



// Projects

@import 'sass/pages/projects/shoot/bkam';
@import 'sass/pages/projects/shoot/burgrill';
@import 'sass/pages/projects/shoot/face_rituals';
@import 'sass/pages/projects/shoot/amazing_vodka';
@import 'sass/pages/projects/shoot/anglow';
@import 'sass/pages/projects/shoot/august';
@import 'sass/pages/projects/shoot/juicy_chemistry';
@import 'sass/pages/projects/shoot/mool_organic';
@import 'sass/pages/projects/shoot/namaste_asia';
@import 'sass/pages/projects/shoot/rosvin_bugs';
@import 'sass/pages/projects/shoot/saint_g';
@import 'sass/pages/projects/shoot/wow';
@import 'sass/pages/projects/shoot/body_cupid';
@import 'sass/pages/projects/shoot/lqi';
@import 'sass/pages/projects/shoot/rosso_brunello';
@import 'sass/pages/projects/shoot/dragon_wagon';
@import 'sass/pages/projects/shoot/poet';
@import 'sass/pages/projects/shoot/gaia';
@import 'sass/pages/projects/shoot/bella_vita_organic';
@import 'sass/pages/projects/shoot/skinella';





body{
    // background-color: rgb(18, 17, 24);
    margin: 0;
}









