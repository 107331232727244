


    .homex{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    }

    .hmx-bd{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: white;


        }


    
    // Hero Section
    
    .herox-sect{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    
    
    
    .herox-wrap{
        width: 100%;
      margin: 0 auto; 
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
    
       .herox-img{
           width: 100vw;
           height: 100vh;
         background-size: cover;
         background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center;
       }
    }
    
    




    

    


    

    
    
    
    // @media (max-width: 1439px){


    //     // .hero-wrap{
    //     //     width: 100%;
            
    //     // }

    //     .hero-wrap{
    //         width: 1650px;
    
    //     }


    // } // 1440px
    
    
    // @media (max-width: 1279px){
        
    //     // .homex{
    //     //     overflow: hidden;
    //     // }

    //     // .hero-wrap{
    //     //     width: $col-7;
    //     // }

    //     .hero-wrap{
    //         width: 1350px;
    
    //     }
        
        
    // } // 1279px
    
    
    
    // @media (max-width: 1023px){
 
    //     .hero-wrap{
    //         width: 1350px;
    
    //     }
        
        
        
        
    // } // 1023px
    
    
    
    // @media (max-width: 767px){

    //     .hero-wrap{
    //         width: 1350px;
    
    //     }

    //     .hm-bd{
    //         position: absolute;
    //         width: 100%;
    //         height: 100%;
    //         overflow: hidden;
    //         background-color: white;
    //         border: 20px solid white;
    //         // border: 2px solid rgba($color: #000000, $alpha: .5);
    
    
    //         }
        
        
    // } // 767px
    
    
    
    // @media (max-width: 639px){
    //     .hero-wrap{
    //         width: 1350px;
    
    //     }
        
        
    // } // 767px



    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
 