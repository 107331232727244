.about{
    position: relative;
    width: 100%; 
    // border: 1px solid #000;    
    }
    
.ab-bd{
        position: relative;
        width: 100%;
        height: 100%;    
    }
         
.ab-sect{
        position: relative;
        width: $col-12;
        margin: 0 auto;
        background-color: white;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        }   

        
.ab-row-1{

   position: relative;

   .row-1-col-1{
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        width:100%;
        height: 100%;
        margin: 0 auto;
        // border: 100px solid rgb(255, 255, 255);
        overflow: hidden;
        


    }  

    .row-1-col-2{
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        h1{
            color: white;
            font-family: $s-font;
            font-weight: 600;
            font-size: 7vw;
            text-align: center;
        }
    }
   
} 


//    Main Content

.ab-content{
position: relative;
width: $col-12;
display: flex;
flex-flow: row wrap;
justify-content: center;
}

.ab-content-wrap{
width: $col-12;
position: relative;
display: flex;
flex-flow: row wrap;
align-items: center;
padding: 0 20px;

}



// Row 3
.ab-row-2{
position: relative;
display: flex;
flex-flow: column wrap;
justify-content: flex-start;
align-items: center;
padding: 20px;
margin-top: 50px;

.row-2-col-1{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    
}

.ab-title{

    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: $p-font;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    font-size: .8rem;
    letter-spacing: 3px;
    color: rgb(80, 80, 80);
    // border-bottom: 1px solid black;
    color: black;
}


.row-2-col-2{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
    padding-left: 0px;
    


    .ab-row-2-para{
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
    }
}

.ab-para{
    margin: 0;
    margin-top: 20px;
    font-family: $p-font;
    font-size: 6vw;
    // line-height: 5rem;
    font-weight: 600;
    text-align: center;
    color: $dark;
}


}


// Row 3
.ab-row-3{
position: relative;
display: flex;
flex-flow: column wrap;
justify-content: center;
align-items: center;
padding: 20px;
margin-top: 10%;

.row-3-col-1{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    
}

.ab-title{

    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: $p-font;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: .8rem;
    letter-spacing: 3px;
    color: rgb(80, 80, 80);
    // border-bottom: 1px solid black;
    color: black;

}


.row-3-col-2{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
    padding-left: 0px;
    


    .ab-row-3-para{
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
    }
}

.ab-para{
    margin: 0;
    margin-top: 20px;
    font-family: $p-font;
    font-size: 6vw;
    // line-height: 5rem;
    font-weight: 600;
    text-align: center;
    color: $dark;
}


}

.ab-row-4{
position: relative;
display: none;
flex-flow: row wrap;
justify-content: flex-end;
align-items: center;
margin-top: 50px;
width: $col-6;
height: 1px;
background: rgb(206, 206, 206);

a{
    font-family: $p-font;
    font-weight: 800;
    font-size: 2vw;
    color: rgb(0, 0, 0);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    // border-bottom: 1px solid black;

    span{
        font-size: 2vw;
        color: rgb(58, 58, 58);
        padding-left: 10px;
    }



    
}
}



.ab-row-5{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-top: 40%;
    margin-bottom: 30%;
    padding: 0px;
    width: $col-12;
   

    
    .cont-info{
        margin: 0 auto;
      
        width: $col-12;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .cont-info-wrap{
        width: $col-12;
       
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        h6{
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        writing-mode: horizontal-rl;
        font-family: $p-font;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.8rem;
        color: $dark;
        // border-bottom: 1px solid $dark;

    span:nth-of-type(1){
        padding-right: 0px;
    }

    span:nth-of-type(2){
        padding-left: 0px;
    }


        }

            p:nth-of-type(1){
                margin: 0;
                font-family: $p-font;
                font-size: 5vw;
                font-weight: 600;
                text-align: center;
                color: $dark;
            }

            p:nth-of-type(2){
                margin-top: 10px;
                font-family: $p-font;
                font-size: 5vw;
                font-weight: 600;
                text-align: center;
                color: $dark;
            }


    }


    .cont-add{
        margin: 0 auto;
        margin-top: 20px;
        width: $col-12;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .cont-add-wrap{
        width: $col-12;
        // border: 1px solid #000;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        h6{
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        writing-mode: horizontal-rl;
        font-family: $p-font;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.8rem;
        color: $dark;
        // border-bottom: 1px solid $dark;

    span:nth-of-type(1){
        padding-right: 0px;
    }

    span:nth-of-type(2){
        padding-left: 0px;
    }


        }

            p:nth-of-type(1){
                margin: 0;
                font-family: $p-font;
                font-size: 5vw;
                font-weight: 600;
                text-align: center;
                color: $dark;
            }

            p:nth-of-type(2){
                margin-top: 10px;
                font-family: $p-font;
                font-size: 5vw;
                font-weight: 700;
                text-align: center;
                color: $dark;
            }


    }

    
}


.ab-row-6{
width: 100%;
// border: 1px solid #000;
position: relative;
display: flex;
flex-flow: column wrap;
justify-content: center;
align-items: center;
margin-bottom: 50%;

.row-6-col-1{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: $col-10;
}

.ab-row-6-social{
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;


    a{
        // border: 1px solid #000;
        font-family: $p-font;
        font-size: 6vw;
        font-weight: 600;
        color: rgb(80, 80, 80);

        .s-text{
            display: none;
        }

        .s-icon{
            display: inline-block;
        }
    }


}




.row-6-col-2{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: $col-10;
    padding-left: 0px;
    padding-top: 5px;

    
}


.ab-row-6-extra{
    // border: 1px solid #000;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;


    div{
        // border: 1px solid #000;
        font-family: $p-font;
        font-size: 2.5vw;
        font-weight: 600;
        color: rgb(80, 80, 80);
    }


}


.row-6-col-3{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    width: $col-12;
    padding-top: 20%;

    .ab-row-6-logo{
        width: $col-3;
    }

    img{
        width: 100%;
        margin: 0 auto;
    }

    
}





}








@media(min-width: 767px){

    .about{
        position: relative;
        width: 100%; 
        // border: 1px solid #000;    
        }
        
    .ab-bd{
            position: relative;
            width: 100%;
            height: 100%;    
        }
             
    .ab-sect{
            position: relative;
            width: $col-12;
            margin: 0 auto;
            background-color: white;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            }   
    
            
    .ab-row-1{
    
       position: relative;
    
       .row-1-col-1{
            box-sizing: border-box;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            position: relative;
            width:100%;
            height: 100%;
            margin: 0 auto;
            // border: 100px solid rgb(255, 255, 255);
            overflow: hidden;
            
    
    
        }  
    
        .row-1-col-2{
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
    
            h1{
                color: white;
                font-family: $s-font;
                font-weight: 600;
                font-size: 7vw;
                text-align: center;
            }
        }
       
    } 
    
    
    //    Main Content
    
    .ab-content{
    position: relative;
    width: $col-12;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    }
    
    .ab-content-wrap{
    width: $col-11;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 20px;
    
    }
    
    
    
    // Row 3
    .ab-row-2{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    
    .row-2-col-1{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
    
        
    }
    
    .ab-title{
    
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: $p-font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;
    }
    
    
    .row-2-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: 100%;
        padding-left: 0px;
        
    
    
        .ab-row-2-para{
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }
    }
    
    .ab-para{
        margin: 0;
        margin-top: 20px;
        font-family: $p-font;
        font-size: 4.5vw;
        font-weight: 600;
        text-align: center;
        color: $dark;
    }
    
    
    }
    
    
    // Row 3
    .ab-row-3{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 10%;
    
    .row-3-col-1{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    
        
    }
    
    .ab-title{
    
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: $p-font;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;
    
    }
    
    
    .row-3-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: 100%;
        padding-left: 0px;
        
    
    
        .ab-row-3-para{
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }
    }
    
    .ab-para{
        margin: 0;
        margin-top: 20px;
        font-family: $p-font;
        font-size: 4.5vw;
        // line-height: 5rem;
        font-weight: 600;
        text-align: center;
        color: $dark;
    }
    
    
    }
    
    .ab-row-4{
    position: relative;
    display: none;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    width: $col-6;
    height: 1px;
    background: rgb(206, 206, 206);
    
    a{
        font-family: $p-font;
        font-weight: 800;
        font-size: 2vw;
        color: rgb(0, 0, 0);
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        text-transform: uppercase;
        text-align: center;
        // border-bottom: 1px solid black;
    
        span{
            font-size: 2vw;
            color: rgb(58, 58, 58);
            padding-left: 10px;
        }
    
    
    
        
    }
    }
    
    
    
    .ab-row-5{
        position: relative;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin-top: 30%;
        margin-bottom: 30%;
        padding: 0px;
        width: $col-12;
       
    
        
        .cont-info{
            margin: 0 auto;
          
            width: $col-12;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }
    
        .cont-info-wrap{
            width: $col-12;
           
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
    
            h6{
            margin: 0;
            margin-bottom: 20px;
            display: flex;
            writing-mode: horizontal-rl;
            font-family: $p-font;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            font-size: 0.9rem;
            color: $dark;
            // border-bottom: 1px solid $dark;
    
        span:nth-of-type(1){
            padding-right: 0px;
        }
    
        span:nth-of-type(2){
            padding-left: 0px;
        }
    
    
            }
    
                p:nth-of-type(1){
                    margin: 0;
                    font-family: $p-font;
                    font-size: 3.5vw;
                    font-weight: 600;
                    text-align: center;
                    color: $dark;
                }
    
                p:nth-of-type(2){
                    margin-top: 10px;
                    font-family: $p-font;
                    font-size: 4vw;
                    font-weight: 600;
                    text-align: center;
                    color: $dark;
                }
    
    
        }
    
    
        .cont-add{
            margin: 0 auto;
            margin-top: 20px;
            width: $col-12;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }
    
        .cont-add-wrap{
            width: $col-12;
            // border: 1px solid #000;
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
    
            h6{
            margin: 0;
            margin-bottom: 20px;
            display: flex;
            writing-mode: horizontal-rl;
            font-family: $p-font;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            font-size: 0.9rem;
            color: $dark;
            // border-bottom: 1px solid $dark;
    
        span:nth-of-type(1){
            padding-right: 0px;
        }
    
        span:nth-of-type(2){
            padding-left: 0px;
        }
    
    
            }
    
                p:nth-of-type(1){
                    margin: 0;
                    font-family: $p-font;
                    font-size: 4vw;
                    font-weight: 600;
                    text-align: center;
                    color: $dark;
                }
    
                p:nth-of-type(2){
                    margin-top: 10px;
                    font-family: $p-font;
                    font-size: 4vw;
                    font-weight: 700;
                    text-align: center;
                    color: $dark;
                }
    
    
        }
    
        
    }
    
    
    .ab-row-6{
    width: 100%;
    // border: 1px solid #000;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50%;
    
    .row-6-col-1{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: $col-10;
    }
    
    .ab-row-6-social{
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    
    
        a{
            // border: 1px solid #000;
            font-family: $p-font;
            font-size: 6vw;
            font-weight: 600;
            color: rgb(80, 80, 80);
    
            .s-text{
                display: none;
            }
    
            .s-icon{
                display: inline-block;
            }
        }
    
    
    }
    
    
    
    
    .row-6-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: $col-10;
        padding-left: 0px;
        padding-top: 5px;
    
        
    }
    
    
    .ab-row-6-extra{
        // border: 1px solid #000;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    
    
        div{
            // border: 1px solid #000;
            font-family: $p-font;
            font-size: 2.5vw;
            font-weight: 600;
            color: rgb(80, 80, 80);
        }
    
    
    }
    
    
    .row-6-col-3{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        width: $col-12;
        padding-top: 20%;
    
        .ab-row-6-logo{
            width: $col-3;
        }
    
        img{
            width: 100%;
            margin: 0 auto;
        }
    
        
    }
    
    
    
    
    
    }
    


}







@media(min-width: 992px){

    .about{
        position: relative;
        width: 100%; 
        // border: 1px solid #000;    
        }
        
.ab-bd{
            position: relative;
            width: 100%;
            height: 100%;    
        }
             
.ab-sect{
            position: relative;
            width: $col-12;
            margin: 0 auto;
            background-color: white;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            }   

            
.ab-row-1{

       position: relative;

       .row-1-col-1{
            box-sizing: border-box;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            position: relative;
            width:100%;
            height: 100%;
            margin: 0 auto;
            // border: 100px solid rgb(255, 255, 255);
            overflow: hidden;
            


        }  

        .row-1-col-2{
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;

            h1{
                color: white;
                font-family: $s-font;
                font-weight: 600;
                font-size: 5vw;
                text-align: center;
            }
        }
       
   } 
   
   
//    Main Content

.ab-content{
    position: relative;
    width: $col-12;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.ab-content-wrap{
    width: $col-10;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 45px;

}
   
   

 // Row 3
.ab-row-2{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    
    .row-2-col-1{
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-start;

        
    }
    
    .ab-title{

        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: $p-font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;
    }
    
    
    .row-2-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: 65%;
        padding-left: 20px;
        


        .ab-row-2-para{
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }
    }

    .ab-para{
        margin: 0;
        margin-top: 20px;
        font-family: $p-font;
        font-size: 2.5vw;
        // line-height: 5rem;
        font-weight: 600;
        text-align: center;
        color: $dark;
    }
    
    
    }
    
    
 // Row 3
 .ab-row-3{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
    
    .row-3-col-1{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        
    }
    
    .ab-title{

        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-family: $p-font;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;

    }
    
    
    .row-3-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: 70%;
        padding-left: 20px;
        


        .ab-row-3-para{
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }
    }

    .ab-para{
        margin: 0;
        margin-top: 20px;
        font-family: $p-font;
        font-size: 2.5vw;
        font-weight: 600;
        text-align: center;
        color: $dark;
    }
    
    
    }

.ab-row-4{
    position: relative;
    display: none;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    width: $col-6;
    height: 1px;
    background: rgb(206, 206, 206);

    a{
        font-family: $p-font;
        font-weight: 800;
        font-size: 2vw;
        color: rgb(0, 0, 0);
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        text-transform: uppercase;
        text-align: center;

        span{
            font-size: 2vw;
            color: rgb(58, 58, 58);
            padding-left: 10px;
        }



        
    }
}



.ab-row-5{
        position: relative;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 200px;
        margin-bottom: 200px;
        padding: 20px;
        width: $col-12;
       

        
        .cont-info{
            margin: 0 auto;
          
            width: $col-10;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        .cont-info-wrap{
            width: $col-12;
           
            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: center;

            h6{
            margin: 0;
            margin-bottom: 20px;
            display: flex;
            writing-mode: horizontal-rl;
            font-family: $p-font;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            font-size: 0.9rem;
            color: $dark;
            border-bottom: 1px solid $dark;

        span:nth-of-type(1){
            padding-right: 5px;
        }

        span:nth-of-type(2){
            padding-left: 5px;
        }


            }

                p:nth-of-type(1){
                    margin: 0;
                    font-family: $p-font;
                    font-size: 1.6rem;
                    font-weight: 600;
                    text-align: center;
                    color: $dark;
                }

                p:nth-of-type(2){
                    margin-top: 10px;
                    font-family: $p-font;
                    font-size: 1.8rem;
                    font-weight: 600;
                    text-align: center;
                    color: $dark;
                }


        }


        .cont-add{
            margin: 0 auto;
            margin-top: 20px;
            width: $col-10;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        .cont-add-wrap{
            width: $col-12;
            // border: 1px solid #000;
            display: flex;
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: center;

            h6{
            margin: 0;
            margin-bottom: 20px;
            display: flex;
            writing-mode: horizontal-rl;
            font-family: $p-font;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            font-size: 0.9rem;
            color: $dark;
            border-bottom: 1px solid $dark;

        span:nth-of-type(1){
            padding-right: 0px;
        }

        span:nth-of-type(2){
            padding-left: 0px;
        }


            }

                p:nth-of-type(1){
                    margin: 0;
                    font-family: $p-font;
                    font-size: 1.2rem;
                    font-weight: 700;
                    text-align: center;
                    color: $dark;
                }

                p:nth-of-type(2){
                    margin-top: 10px;
                    font-family: $p-font;
                    font-size: 1.4rem;
                    font-weight: 700;
                    text-align: center;
                    color: $dark;
                }


        }

        
}


.ab-row-6{
    width: 100%;
    // border: 1px solid #000;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    
    .row-6-col-1{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: $col-12;
    }

    .ab-row-6-social{
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;


        a{
            // border: 1px solid #000;
            font-family: $p-font;
            font-size: 1.5rem;
            font-weight: 600;
            color: rgb(80, 80, 80);

            .s-text{
                display: inline-block;
            }
    
            .s-icon{
                display: none;
            }
        }


    }
    
    


    .row-6-col-2{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        width: $col-12;
        padding-left: 0px;
        padding-top: 5px;

        
    }


    .ab-row-6-extra{
        // border: 1px solid #000;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;


        div{
            // border: 1px solid #000;
            font-family: $p-font;
            font-size: .8rem;
            font-weight: 500;
            color: rgb(80, 80, 80);
        }


    }


    .row-6-col-3{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        width: $col-12;
        padding-top: 50px;

        .ab-row-6-logo{
            width: $col-1;
        }

        img{
            width: 100%;
            margin: 0 auto;
        }

        
    }


    
    
    
    }





}