.project{
    box-sizing: border-box;
    width: $col-12;
    height: 100%;
    position: relative;   
    
}



 
    
.pr-sect{
    position: relative;
     width: $col-12;
     background-color: white;
     display: flex;
     flex: row wrap;
     justify-content: center;
     align-items: center;
     height: 100vh;
 }   
 
.pr-sect-wrap{
    position: absolute;
    top: 180px;
    padding: 0.05px;
    width: $col-9;    
    height: auto;
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 100px;
    z-index: 10000;
 }


.pr-shoots{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    h6{
        font-family: $p-font;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 3vw;

        span{
            display: none;
        }
    }



    .pr-shoot-column{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    .pr{
        padding-bottom: 5%;
        position: relative; 
        }

        a{
            font-family: $p-font;
            font-weight: 800;
            font-size: 6vw;
            color: rgb(58, 58, 58);
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            // z-index: 10;
            // border: 1px solid #000;

            span{
                font-size: 5vw;
                color: rgb(58, 58, 58);
                padding-right: 2vw;
            }

            
        }


        .hover-img{
            // display: none;
            // border: 1px solid #000;
            position: absolute;
            bottom: 0;
            right: 0;
            
            transform: scale(0.7) translateY(100%);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: transform .5s ease-in-out, opacity .5s ease-in-out;
            width: 35vw;

        }

        .pr:hover .hover-img{
            transform: scale(1) translateY(120%);
            opacity: 1;
            visibility: visible;
        }



    }


}





.pr-films{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

    h6{
        font-family: $p-font;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 3vw;

        span{
            color: red;
            display: none;
        }
    }



    .pr-films-column{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;

        .pr{
            padding-bottom: 5%;
        }

        a{
            font-family: $p-font;
            font-weight: 800;
            font-size: 6vw;
            color: rgb(58, 58, 58);
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            span{
                font-size: 5vw;
                color: rgb(58, 58, 58);
                padding-right: 2vw;
            }

            
        }


        



    }


}




/*********************

    Breakpoint Minimum 767px
    
*******************/


@media (min-width: 767px){
    // Set breakpoints if needed
}



@media (min-width: 992px){
    
    .project{
        box-sizing: border-box;
        width: $col-12;
        height: 100%;
        position: relative;   
    }
    
    
    
     
        
    .pr-sect{
        position: relative;
         width: $col-12;
         background-color: white;
         display: flex;
         flex: row wrap;
         justify-content: center;
         align-items: center;
         height: 100vh;
     }   
     
    .pr-sect-wrap{
        position: absolute;
        top: 180px;
        padding: 0.05px;
        width: $col-10;    
        height: auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        padding-bottom: 100px;
     }
    
    
    .pr-shoots{
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
    
        h6{
            font-family: $p-font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;
    
            span{
                color: red;
                display: none;
            }
        }
    
    
    
        .pr-shoot-column{
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
        
            .pr{
                padding-bottom: 5%;
                position: relative; 
                }
        
                a{
                    font-family: $p-font;
                    font-weight: 700;
                    font-size: 3vw;
                    color: rgb(58, 58, 58);
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    // z-index: 10;
                    // border: 1px solid #000;
        
                    span{
                        font-size: 2vw;
                        color: rgb(58, 58, 58);
                        padding-right: 2vw;
                    }
        
                    
                }
        
        
                .hover-img{
                    // display: none;
                    // border: 1px solid #000;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    
                    transform: scale(0.7) translateX(100%);
                    opacity: 0;
                    visibility: hidden;
                    z-index: 1;
                    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
                    width: 15vw;
        
                }
        
                .pr:hover .hover-img{
                    transform: scale(1) translateX(120%);
                    opacity: 1;
                    visibility: visible;
                }
        
        
        
            }
    
    
    }
    
    
    
    
    
    .pr-films{
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
    
        h6{
            font-family: $p-font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: .9rem;
        letter-spacing: 3px;
        color: rgb(80, 80, 80);
        // border-bottom: 1px solid black;
        color: black;
    
            span{
                color: red;
            }
        }
    
    
    
        .pr-films-column{
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
    
            .pr{
                padding-bottom: 5%;
            }
    
            a{
                font-family: $p-font;
                font-weight: 700;
                font-size: 3vw;
                color: rgb(58, 58, 58);
                display: flex;
                flex-flow: row wrap;
                align-items: center;
    
                span{
                    font-size: 2vw;
                    color: rgb(58, 58, 58);
                    padding-right: 2vw;
                }
    
                
            }
    
    
    
        }
    
    
    }
}


@media (min-width: 1200px){
    // Set breakpoints if needed
}



@media (min-width: 1500px){
    // Set breakpoints if needed
}






