// Layout Styling




// Columns

$col-1: 8.33333333%;
$col-2: 16.66666667%;
$col-3: 25%;
$col-4: 33.33333333%;
$col-5: 41.66666667%;
$col-6: 50%;
$col-7: 58.33333333%;
$col-8: 66.66666667%;
$col-9: 75%;
$col-10: 83.33333333%;
$col-11: 91.66666667%;
$col-12: 100%;


// Grid Margin
$gridMargin: 2.777777777777778%;

// Grid Gutter
$gridGutter: 1.388888888888889%;


// Borders Style for Reference


@mixin  redb ($thick: 1px){
    border: $thick solid red;   
}

@mixin  blackb ($thick: 1px){
    border: $thick solid black;   
}

@mixin  whiteb ($thick: 1px){
    border: $thick solid white;   
}
