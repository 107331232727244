


    


.header-home{
    position: fixed;
    z-index: 10;
    top: 50px;
    left: 50px;
    right: 50px;
    width: 100%;
    overflow: hidden;
    // border: 1px solid #000;
}

.header-home-wrap{
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items:flex-start;
    margin: 0 auto;
    
    
    
    
    
    .home-logo-sect{
        img{
            width: 60px;
        }
        
        
        
        
    }
    



}





@media (max-width: 820px){

    .header-home{
        
        position: fixed;
        z-index: 10;
        top: 20px;
        left: 20px;
        right: 0;
        width: 100%;
        padding: 0 10px;
        padding-right: 20px;
        overflow: hidden;
    }
    
    .header-home-wrap{
        box-sizing: border-box;
        height: 140px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items:flex-start;
        margin: 0 auto;
        
        
        
        
        
        .home-logo-sect{
    
            margin-top: 10px;
            img{
                width: 50px;
            }
            
            
            
            
        }
        
        

    
    
    }
    



}

