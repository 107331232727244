.cursor {
   // display: none !important;
    width: 80px;
    height: 80px;
    border: 1px solid #121212;
    border-radius: 100%;
    position: fixed;
    transform: translate(-50%, -60%);
    z-index: 9;
    transition: all 100ms ease;
 transition-property: opacity, background-color, transform;
    background-blend-mode: overlay;
}

.c-clicked {
    transform: translate(-50%, -50%) scale(0.5);
    background-color: #121212;
 }


 .cursor-link-hovered {
    transform: translate(-50%, -50%) scale(1.25);
 }

 .cursor{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-blend-mode: overlay;
     a{
        background-blend-mode: overlay;
         font-family: $p-font;
         font-weight: 700;
         font-size: 0.9rem;
         text-align: center;
         text-transform: uppercase;
        //  border: 1px solid #000;
        padding: 10px;
         
     }
 }

 .enter{
    display: none;
 }


@media(max-width: 992px){

   .cursor{
      display: none;
   }

   // .enter{
      
   //    position: fixed;
   //    width: 100%;
   //    height: 100vh;
   //    top: 40px;
   //    display: flex;
   //    flex-flow: row wrap;
   //    justify-content: flex-end;
   //    align-items: flex-start;
   //    z-index: 9;
   //    padding-right: 20px;
   //    font-family: $p-font;
   //    font-weight: 800;
   //    font-size: 1.2rem;
   //    letter-spacing: 3px;
   //    text-transform: uppercase;
      
   //    span{
   //       // background: rgba($color: #fff, $alpha: 0.3 );
   //       // border-bottom: 1px solid #000;
   //    }

   .enter{
      
      position: fixed;
      width: 100%;
      height: 100vh;
      bottom: 20px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: flex-end;
      z-index: 9;
      // padding-left: 20px;
      font-family: $p-font;
      font-weight: 800;
      font-size: 10vw;
      // letter-spacing: 3px;
      text-transform: uppercase;
      
      span{
         color: white;
         // background: rgba($color: #fff, $alpha: 0.3 );
         border-bottom: 2px solid rgb(255, 255, 255);
      }




   }


   



}

