


    


.header{
    position: fixed;
    z-index: 10;
    top: 40px;
    left: 10px;
    // right: 50px;
    width: 100%;
    overflow: hidden;
    // border: 1px solid #000;
}

.header-wrap{
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items:flex-start;
    margin: 0 auto;
    
    
    
    
    
    .logo-sect{
        img{
            width: 25px;
        }
        
        
        
        
    }


}








@media (min-width: 992px){

    .header{
        
        position: fixed;
        z-index: 10;
        top: 40px;
        left: 30px;
        right: 0;
        width: 100%;
        padding: 0 10px;
        overflow: hidden;
    }
    
    .header-wrap{
        box-sizing: border-box;
        height: 140px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items:flex-start;
        margin: 0 auto;
        
        
        
        
        
        .logo-sect{
    
            img{
                width: 30px;
            }
            
            
            
            
        }
        
    
    
    }
    



}

