// .loader{
//     overflow: hidden;
//     position: fixed;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     top: 0;
//     // border: 1px solid #000;
//     z-index: 1000;
//     background: rgba($color: #262729, $alpha: 1);
//     // display: none;
//     transform: translateX(-100%);
//     // width: 200%;
// }




.loader-top{
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 50%;
    right: 0;
    top: 0;
    // border: 1px solid #000;
    z-index: 1000;
    background-color: rgb(18, 17, 24);
    // display: none;
    transform: translateX(-100%);
    // width: 200%;
}


.loader-bottom{
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 50%;
    // border: 1px solid #000;
    z-index: 1000;
    background-color: rgb(18, 17, 24);
    // display: none;
    transform: translateY(100%);
    // width: 200%;
}
