   // footer Section (sticky)
    


   .footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.footer-wrap{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items:center;


    .f-follow-sect{
        // border: 1px solid #000;
        margin-bottom: 120px;
        height: 100%;


        a{  
            display: block;
            font-family: $p-font;
            font-weight: 700;
            font-size: 0.9rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            transform: rotate(90deg) translateY(-10px);
            transform-origin: bottom bottom;
            z-index: 9999;
            color: $dark;
            
        }

        .f-work{
            transform: translateY(-5rem);
            
        }

        .ab-line{
            display: none;
            width: 1px;
            height: 30px;
            background: $dark;
            position: fixed;
            color: $dark;
            bottom: 40px;
            right: 22px;
            
        }

    }


   

    
}




@media (min-width: 992px){
    // footer Section (sticky)
    


   .footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.footer-wrap{
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items:center;


    .f-follow-sect{
        // border: 1px solid #000;
        margin-bottom: 120px;
        height: 100%;


        a{  
            display: block;
            font-family: $p-font;
            font-weight: 700;
            font-size: 0.9rem;
            letter-spacing: 2px;
            text-transform: uppercase;
            transform: rotate(90deg) ;
            transform-origin: bottom bottom;
            z-index: 9999;
            color: $dark;
            
        }

        .f-work{
            transform: translateY(-5rem);
            
        }

        .ab-line{
            display: none;
            width: 1px;
            height: 30px;
            background: $dark;
            position: fixed;
            color: $dark;
            bottom: 40px;
            right: 34px;
            
        }

    }


   

    
}
    }

