// Hover Animation




// Header

.header{

   .nav-hover a{
        position: relative;
        width: auto;
        padding-bottom: 2px;
    }
    
    
    .nav-hover a::before{
        content: "";
        display: inline-table;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        width:  0;
        height: 1px;
        background: #000;
        transition: width 0.3s linear;
    }


    .nav-hover a:hover::before{
        width: 100%;
    }

}


.header_pr{

    .nav-hover a{
         position: relative;
         width: auto;
         padding-bottom: 2px;
     }
     
     
     .nav-hover a::before{
         content: "";
         display: inline-table;
         position: absolute;
         box-sizing: border-box;
         bottom: 0;
         left: 0;
         width:  0;
         height: 1px;
         background: #fff;
         transition: width 0.3s linear;
     }
 
 
     .nav-hover a:hover::before{
         width: 100%;
     }
 
 }






// Projects

.project{

    a{
        position: relative;
        width: auto;
        padding-bottom: 2px;
    }
    
    
    a::before{
        content: "";
        display: inline-table;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        width:  0;
        height: 1px;
        background: rgb(41, 41, 41);
        transition: width 0.3s linear, scale 0.2s linear;
    }


    a:hover::before{
        width: 100%;
    }

}




// Overview


.overview{

    .pr-hover a{
         position: relative;
         width: auto;
         padding-bottom: 2px;
     }
     
     
     .pr-hover a::before{
         content: "";
         display: inline-table;
         position: absolute;
         box-sizing: border-box;
         bottom: 0;
         left: 0;
         width:  0;
         height: 1px;
         background: #fff;
         transition: width 0.3s linear;
     }
 
 
     .pr-hover a:hover::before{
         width: 100%;
     }
 
 }



 .nav-footer{

    .f-work a{
         position: relative;
         width: auto;
         padding-bottom: 2px;
     }
     
     
     .f-work a::before{
         content: "";
         display: inline-table;
         position: absolute;
         box-sizing: border-box;
         bottom: 0;
         left: 0;
         width:  90%;
         height: 1px;
         background: #000;
         transition: width 0.3s linear;
     }
 
 
     .f-work a:hover::before{
         width: 90%;
     }



// About Link

     .f-ab a{
        position: relative;
        width: auto;
        padding-bottom: 2px;
    }
    
    
    .f-ab a::before{
        content: "";
        display: inline-table;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        width:  0;
        height: 1px;
        background: #000;
        transition: width 0.3s linear;
    }


    .f-ab a:hover::before{
        width: 100%;
    }
 
 }




 .ab-footer{

    .f-ab a{
         position: relative;
         width: auto;
         padding-bottom: 2px;
     }
     
     
     .f-ab a::before{
         content: "";
         display: inline-table;
         position: absolute;
         box-sizing: border-box;
         bottom: 0;
         left: 0;
         width:  100%;
         height: 1px;
         background: #000;
         transition: width 0.3s linear;
     }
 
 
     .f-ab a:hover::before{
         width: 100%;
     }



// About Link

     .f-work a{
        position: relative;
        width: auto;
        padding-bottom: 0px;
    }
    
    
    .f-work a::before{
        content: "";
        display: inline-table;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        width:  0;
        height: 1px;
        background: #000;
        transition: width 0.3s linear;
    }


    .f-work a:hover::before{
        width: 100%;
    }
 
 }













 .footer_pr{

    .f-about a{
         position: relative;
         width: auto;
         padding-bottom: 2px;
     }
     
     
     .f-about a::before{
         content: "";
         display: inline-table;
         position: absolute;
         box-sizing: border-box;
         bottom: 0;
         left: 0;
         width:  0;
         height: 1px;
         background: rgb(255, 255, 255);
         transition: width 0.3s linear;
     }
 
 
     .f-about a:hover::before{
         width: 100%;
     }
 
 }





