// Color Variables & common functions



// Colors Values

 $lg: #dadada;


 $dark: rgb(64, 64, 75);
 