.saint_g{
    box-sizing: border-box;
    width: $col-12;
    height: 100%;
    position: relative;
}



.saint_g_wrapper{
    width: $col-12;
    height: $col-12;
 }
 
 

 
    
.saint_g-sect{
     width: $col-12;
     background-color: rgb(18, 17, 24);
     display: flex;
     flex: row wrap;
     justify-content: center;
     align-items: center;
 }   
 
.saint_g-sect-wrap{
    padding: 0.05px;
    width: $col-10;    
 }


// Row 1
.saint_g-row-0{

        bottom: 0;
        left: 0;
        position: fixed;
        width: 60%;   
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        z-index: 0;
        font-size: 0px;

        h1{
            padding: 0;
            margin: 0;
            font-family: $p-font;
            font-size: 15vw;
            letter-spacing: 3px;
            line-height: 13vw;
            text-transform: uppercase;
            color: rgba($color: rgb(235, 235, 235), $alpha: 0.8);
        }



    
} 



// Row 1
.saint_g-row-1{
    display: none;  // checked 
} 


// Row 2
.saint_g-row-2{
    position: relative;
    z-index: 2;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 200px;

    img{
        width: 100%;
        max-width: 100%;
    }
}



// Row 3
.saint_g-row-3{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}




// Row 4
.saint_g-row-4{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}




// Row 5
.saint_g-row-5{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}



// Row 6
.saint_g-row-6{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}



// Row 7
.saint_g-row-7{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}


// Row 8
.saint_g-row-8{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}



// Row 9
.saint_g-row-9{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}



// Row 10
.saint_g-row-10{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: $col-12;
    margin-top: 300px;

    img{
        width: 100%;
        max-width: 100%;
    }
}




.saint_g-row-11{
    position: relative;
    width: $col-12;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 100px;
    z-index: 5;
    
    .saint_g-row-11-wrapper{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    color: white;
    
    }
    
    
    .row-11-col-1{
    margin: 0 auto;
    padding-right: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    text-align: right;
    // @include whiteb;
    width: $col-12;
    
    
    
    .row-11-col-1-wrap{
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        }
        
    
    
    
    .saint_g-next-pr{
    
    p{
        padding: 0;
        margin: 0;
        font-family: $p-font;
        font-size: 3vw;
        font-weight: 700;
        letter-spacing: -1px;
        text-transform: uppercase;
        color: rgb(207, 207, 218);
        // @include whiteb;
    }
    }
    
    
    .saint_g-pr-link{
    
    a{
        display: inline-block;
        padding-bottom: 0.2rem;
        margin: 0;
        margin-top: 2rem;
        font-family: $p-font;
        font-size: 5vw;
        font-weight: bolder;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: rgb(207, 207, 218);
        border-bottom: 1px solid white;
        // @include whiteb;
    }
    }
    
    }
    
    
    
    
    .row-11-col-2{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    
    .saint_g-next-img{
        width: 100%;
    }
    }
    
    
    
    
    
    } // Row 11 ends here











// Small Screen Layout


/*********************

    Breakpoint Minimum 767px
    
*******************/


@media (min-width: 767px){
    // Codes goes here

    .saint_g-row-11{
        position: relative;
        width: $col-12;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 300px;
        z-index: 5;
        
        .saint_g-row-11-wrapper{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        color: white;
        
        }
        
        
        .row-11-col-1{
        margin: 0 auto;
        padding-right: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        text-align: right;
        // @include whiteb;
        width: $col-12;
        
        
        
        .row-11-col-1-wrap{
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            }
            
        
        
        
        .saint_g-next-pr{
        
        p{
            padding: 0;
            margin: 0;
            font-family: $p-font;
            font-size: 2vw;
            font-weight: 700;
            letter-spacing: -1px;
            text-transform: uppercase;
            color: rgb(207, 207, 218);
            // @include whiteb;
        }
        }
        
        
        .saint_g-pr-link{
        
        a{
            display: inline-block;
            padding-bottom: 0.2rem;
            margin: 0;
            margin-top: 2rem;
            font-family: $p-font;
            font-size: 5vw;
            font-weight: bolder;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: rgb(207, 207, 218);
            border-bottom: 1px solid white;
            // @include whiteb;
        }
        }
        
        }
        
        
        
        
        .row-11-col-2{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        width: 100%;
        margin-top: 20px;
        
        .saint_g-next-img{
            width: 100%;
        }
        }
        
        
        
        
        
        } // Row 11 ends here

}



















/*********************

    Breakpoint Minimum 992px
    
*******************/


@media (min-width: 992px){

    .saint_g-sect{
        width: $col-12;
        background-color: rgb(18, 17, 24);
        display: flex;
        flex: row wrap;
        justify-content: center;
        align-items: center;
    }   
    
   .saint_g-sect-wrap{
       padding: 0.05px;
       width: $col-10;    
    }
   



    // Row 1
.saint_g-row-0{

    bottom: 0;
    left: 0;
    position: fixed;
    width: 60%;   
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    z-index: 0;
    font-size: 0px;

    h1{
        padding: 0;
        margin: 0;
        font-family: $p-font;
        font-size: 15vw;
        letter-spacing: 3px;
        line-height: 13vw;
        text-transform: uppercase;
        color: rgba($color: rgb(35, 34, 37), $alpha: 0.8);
    }




} 



// Row 1
.saint_g-row-1{
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 200px;
    // @include redb;
    display: flex;  // checked 
    flex-flow: row wrap;
    justify-content: flex-start;
    width: $col-12;
    overflow: hidden;
    
            .row-1-col{
                width: 50%;
            }
    
            img{
                width: 100%;
                max-width: 100%;
            }


} 


// Row 2
.saint_g-row-2{
position: absolute;
z-index: 2;
right: 0;
bottom: 0;
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
align-items: center;
width: $col-12;
padding: 0 70px;
margin-top: 0;
overflow: hidden;

.row-2-col{
    width: $col-3;
}

img{
    width: 100%;
    max-width: 100%;
}
}



// Row 3
.saint_g-row-3{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-start;
align-items: center;
width: $col-12;
margin-top: 70%;

.row-3-col{
    width: $col-4;

}

img{
    width: 100%;
    max-width: 100%;
}
}




// Row 4
.saint_g-row-4{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
align-items: center;
width: $col-12;
margin-top: 300px;


.row-4-col{
    width: $col-4;

}


img{
    width: 100%;
    max-width: 100%;
}
}




// Row 5
.saint_g-row-5{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-start;
align-items: center;
width: $col-12;
margin-top: 300px;


.row-5-col{
    width: $col-5;

}

img{
    width: 100%;
    max-width: 100%;
}
}



// Row 6
.saint_g-row-6{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
width: $col-12;
margin-top: 300px;


.row-6-col{
    width: $col-5;

}

img{
    width: 100%;
    max-width: 100%;
}
}



// Row 7
.saint_g-row-7{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
align-items: center;
width: $col-12;
margin-top: 300px;

.row-7-col{
    width: $col-5;

}

img{
    width: 100%;
    max-width: 100%;
}
}


// Row 8
.saint_g-row-8{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-start;
align-items: center;
width: $col-12;
margin-top: 300px;

.row-8-col{
    width: $col-4;

}

img{
    width: 100%;
    max-width: 100%;
}
}



// Row 9
.saint_g-row-9{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
align-items: center;
width: $col-12;
margin-top: 300px;

.row-9-col{
    width: $col-5;

}

img{
    width: 100%;
    max-width: 100%;
}
}



// Row 10
.saint_g-row-10{
position: relative;
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
width: $col-12;
margin-top: 300px;

.row-10-col{
    width: $col-8;

}

img{
    width: 100%;
    max-width: 100%;
}
}




.saint_g-row-11{
position: relative;
width: $col-12;
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
color: white;
margin-top: 300px;
z-index: 5;

.saint_g-row-11-wrapper{
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items: center;
color: white;

}


.row-11-col-1{
margin: 0 auto;
padding-right: 0;
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
text-align: right;
// @include whiteb;
width: $col-12;



.row-11-col-1-wrap{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    }
    



.saint_g-next-pr{

p{
    padding: 0;
    margin: 0;
    font-family: $p-font;
    font-size: 2vw;
    font-weight: 700;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: rgb(207, 207, 218);
    // @include whiteb;
}
}


.saint_g-pr-link{

a{
    display: inline-block;
    padding-bottom: 0.2rem;
    margin: 0;
    margin-top: 2rem;
    font-family: $p-font;
    font-size: 5vw;
    font-weight: bolder;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: rgb(207, 207, 218);
    border-bottom: 1px solid white;
    // @include whiteb;
}
}

}




.row-11-col-2{
display: flex;
flex-flow: row wrap;
justify-content: flex-end;
width: 100%;
margin-top: 20px;

.saint_g-next-img{
    width: 100%;
}
}





} // Row 11 ends here













}








// Medium Screen Layout


/*********************

    Breakpoint Minimum 1200px
    
*******************/


@media (min-width: 1200px){
    // Codes goes here
}







// large Screen Layout


/*********************

    Breakpoint Minimum 1500px
    
*******************/


@media (min-width: 1500px){
    // Codes goes here
}








// larger Screen Layout


/*********************

    Breakpoint Minimum 1900px
    
*******************/


@media (min-width: 1900px){
    // Codes goes here
}













































































